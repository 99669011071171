import React, { useEffect, useRef } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete,
  TableContainer,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Container,
  Divider,
  Radio,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import IconButton from "@mui/material/IconButton";
import { object } from "prop-types";
import { indianStates } from "../../../utils/utils";
import { editClientGuide } from "./guide";
import MultiAddressInput, { onSubmitAddress } from "./multiAddressInput";
import { allSettings } from "../../../utils/components/allSettings";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import EditWrap from "../../commonComponent/editWrap";

const TableCellCss = {
  padding: "10px 20px",
  borderBottom: "none",
  border: "1px solid gray",
};

const filter = createFilterOptions();

export default function UpdatreClient(props) {
  const editClientGuides = editClientGuide();
  const [companyName, setCompanyNanme] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [addressIds, setAddressIds] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [shippingAddressIds, setShippingAddressIds] = React.useState("");
  const [contactDetailsRows, setContactDetailsRowsRows] = React.useState([
    ["", "", ""],
  ]);
  const [calibratedByChecked, setCalibratedByChecked] = React.useState("false");
  const [checkedByChecked, setCheckedByChecked] = React.useState("false");
  const [approvedByChecked, setApprovedByChecked] = React.useState("false");
  const [reviewedByChecked, setReviewedByChecked] = React.useState("false");
  const [signedByChecked, setSignedByChecked] = React.useState("false");
  const [branches, setBranches] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState(null);

  const [allRole_PossibleFields, setAllRole_PossibleFields] = React.useState(
    {}
  );
  const [selectedRolesValue, setSelectedRolesValue] = React.useState({
    role1: { keyName: "role1", type: 2, value: "false", status: 1 },
    role2: { keyName: "role2", type: 2, value: "false", status: 1 },
    role1Label: { keyName: "role1Label", type: 2, value: "false", status: 1 },
    role2Label: { keyName: "role2Label", type: 2, value: "false", status: 1 },
    role3: { keyName: "role3", type: 2, value: "false", status: 1 },
    role4: { keyName: "role4", type: 2, value: "false", status: 1 },
    role5: { keyName: "role5", type: 2, value: "false", status: 1 },
    others: [],
  });

  const [allSettingsArray, setAllSettingsArray] = React.useState([]);
  const [currentClientID, setCurrentClientID] = React.useState(null);
  const [allAddresses, setAllAddresses] = React.useState([]);
  const [ClientSettingsData, setClientSettingsData] = React.useState([]);
  const [filterClientSettingsArray, setFilterClientSettingsArray] = React.useState([]);
  const [isSettingSection, setIsSettingSection] = React.useState(false);
  const[companyHierarchySettingArray, setCompanyHierarchySettingArray]=React.useState([]);
  const [selectedCompanyHierarchy, setSelectedCompanyHierarchy] = React.useState({});
  const[companyHierarchyOptions, setCompanyHierarchyOptions]=React.useState([[],[]]);
  const[hierarchyResultArray, setHierarchyResultArray]=React.useState([[],[]]);
  const[contactDetailsData, setContactDetailsData]=React.useState([]);
  const[remainingContactData, setremainingContactData]=React.useState([]);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const newCompanyHierarchy = Object.values(selectedCompanyHierarchy).join(',');

  

  const params = useParams();
    

  const readingColumns = [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "address",
        headerName: "Address",
        editable: true,
      },
      {
        field: "dept",
        headerName: "Department",
        editable: true,
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name",
        editable: true,
      },

      {
        field: "contactNo",
        headerName: "Contact No",
        editable: true,
        validate: (value) => /^\d{10}$/.test(value),
        errorText: '(10 digits required)',
      },
      {
        field: "email",
        headerName: "Email Id",
        editable: true,
        validate: (value) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
        errorText: 'Invalid email address',
      },   
  ];

  const getBranches = (setBranch) => {
    axiosWithToken.get(BASE_URL + "branch?_where=(status,eq,1)").then((res) => {
      setBranch(res.data);
      setBranches(
        res.data.map((item) => {
          return {
            id: item.id,
            branchName: item.branchName,
          };
        })
      );
    });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...contactDetailsRows];
    newRows[rowIndex][colIndex] = value;
    setContactDetailsRowsRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...contactDetailsRows];
    newReadingRows.push(["", "","", "", ""]);
    setContactDetailsRowsRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...contactDetailsRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      setContactDetailsRowsRows([...newReadingRows]);
    }
  };

  const initiateChData = () => {
    try {
      let rows = [];
      var contactDetailsArray = [];
      if (contact != "") {
        contactDetailsArray = JSON.parse(contact);
      }

      for (let i = 0; i < contactDetailsArray.length; i++) {
        rows.push([
          contactDetailsArray[i].contact,
          contactDetailsArray[i].dept,
          contactDetailsArray[i].contactPersonName,
          contactDetailsArray[i].emailId,
        ]);
      }
      setContactDetailsRowsRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const convertContactDetailsToRows = () => {

// Filter the data based on the companyHierarchy
const filteredData = contactDetailsData.filter(
  (item) => item.companyHierarchy === newCompanyHierarchy || ""
);

// Create an array for remaining data (not matching the filter)
const remainingData = contactDetailsData.filter(
  (item) => item.companyHierarchy !== newCompanyHierarchy || ""
);
setremainingContactData(remainingData)

    let rows = [];
  
    if (filteredData && filteredData.length > 0) {
      for (let i = 0; i < filteredData.length; i++) {
        rows.push([
          filteredData[i].address,
          filteredData[i].dept,
          filteredData[i].contactPersonName,
          filteredData[i].contact,
          filteredData[i].emailId,
        ]);
      }
    }
  
    setContactDetailsRowsRows(rows);
  };

  useEffect(()=>{
    convertContactDetailsToRows()
  },[contactDetailsData,newCompanyHierarchy])

  const getClientsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients/${params.id}`)
      .then((res) => {
        let newData = res.data[0];
        setCompanyNanme(newData?.companyName);
        setContact(newData?.contact);
        setAddressIds(newData?.address);
        setShippingAddressIds(newData?.shippingAddress);
        setGstNumber(newData?.gstNumber);
        setState(newData?.state);
        setEmail(newData?.email);
        // setSignature(newData?.signature);
        getBranches((inpt) => {
          let tmp = {};
          inpt.map((e) => (tmp[e.id] = e));
          newData?.branch
            ? setSelectedBranch(tmp[newData?.branch] || null)
            : setSelectedBranch(null);
        });


      const contactDetailsArray = newData?.contact ? JSON.parse(newData.contact) : [];
      setContactDetailsData(contactDetailsArray)
       
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = async () => {
    try {
      const AllIds = await onSubmitAddress(
        allAddresses,
        setShippingAddressIds,
        setAddressIds
      );

      const rows = contactDetailsRows.map((row) => ({
        address: row[0] || "",
        dept: row[1] || "",
        contactPersonName: row[2] || "",
        contact: row[3] || "",
        emailId: row[4] || "",
        companyHierarchy:newCompanyHierarchy || "",
      }));

      const mergedRows = [...rows, ...remainingContactData];

      const checkedObj = {
        forOrganization: {
          calibratedBy: calibratedByChecked.toString(),
          checkedBy: checkedByChecked.toString(),
          approvedBy: approvedByChecked.toString(),
        },
        forClient: {
          reviewedBy: reviewedByChecked.toString(),
          signedBy: signedByChecked.toString(),
        },
      };

      const jsonData = {
        companyName,
        contact: mergedRows.length>0 ? JSON.stringify(mergedRows) : null,
        address:
          addressIds && AllIds?.[0]
            ? `${addressIds},${AllIds?.[0]}`
            : addressIds || AllIds?.[0] || "",
        shippingAddress:
          shippingAddressIds && AllIds?.[1]
            ? `${shippingAddressIds},${AllIds?.[1]}`
            : shippingAddressIds || AllIds?.[1] || "",
        gstNumber,
        state,
        email,
        signature: JSON.stringify(checkedObj),
        branch: selectedBranch ? `${selectedBranch?.id}` : null,
      };

      const url = BASE_URL;
      const allSettingsWithHierarchy = allSettingsArray.map(obj => ({
        ...obj,
        companyHierarchy: newCompanyHierarchy
    }));

      const axiosCall = params.id
        ? axiosWithToken.patch(url + `clients/${params.id}`, jsonData)
        : axiosWithToken.post(url + `clients`, jsonData);

      await axiosCall;

      if (params.id) {
        const sqlQuery = {
          query: `DELETE FROM clientSettings WHERE clientId=${params.id} and companyHierarchy='${newCompanyHierarchy}'`,
        };
        await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
      }

      await axiosWithToken.post(url + "clientSettings/bulk", allSettingsWithHierarchy);

      toast.success(params.id ? "Clients updated!" : "Client Created!");
    } catch (error) {
      if (error.message !== "request_aborted") {
        toast.error(error.message || "Something Went Wrong!");
      }
    } finally {
      props.setLoader(false);
    }
  };

  const handleCompanyHierarchyChange = (label, value) => {
    setSelectedCompanyHierarchy((prev) => ({
      ...prev,
      [label]: value,
    }));
  };


  useEffect(async () => {
    const AllSettings = await allSettings();
    let SettingValue = AllSettings.find(
      (ele) => ele.keyName === "Client Settings"
    )?.value;
    if (SettingValue === "true") {
      setIsSettingSection(true);
    }

    let companyHierarchySetting = AllSettings.find(
      (ele) => ele.keyName === "companyHierarchy"
    )?.value;
    setCompanyHierarchySettingArray(companyHierarchySetting?companyHierarchySetting?.split(","):"")

    let rolesToCheck = [
      "role1_PossibleFields",
      "role2_PossibleFields",
      "role3_PossibleFields",
      "role4_PossibleFields",
      "role5_PossibleFields",
      "others_PossibleFields",
      "role2Label_PossibleFields",
      "role1Label_PossibleFields",
    ];

    let allRolesPossibleFields = AllSettings.filter((ele) => {
      return rolesToCheck.some((role) => ele.keyName.includes(role));
    });

    // Remove id and lastModified from each object
    let cleanedData = allRolesPossibleFields?.map(
      ({ id, lastModified, ...rest }) => rest
    );

    // Organize data by keyName
    let organizedData = {};

    cleanedData?.forEach(({ keyName, value, type, status }) => {
      let labels = value.split(",").map((label) => ({
        label,
        keyName: keyName.split("_")[0],
        type,
        value: "false",
        status,
      }));
      if (!organizedData[keyName]) {
        organizedData[keyName] = [];
      }
      organizedData[keyName].push(...labels);
    });

    // Handling other keyName values
    let otherKeyName = "others_PossibleFields";
    let otherData = organizedData[otherKeyName] || [];

    delete organizedData[otherKeyName]; // Remove others key from organizedData

    // Modify objects in otherData to match the desired structure
    let modifiedOtherData = otherData.map(({ label, type, status }) => ({
      label,
      keyName: label,
      type,
      value: "false",
      status,
    }));

    // Add modifiedOtherData back to organizedData under otherKeyName
    organizedData[otherKeyName] = modifiedOtherData;

    setAllRole_PossibleFields(organizedData);
    setSelectedRolesValue((prevState) => ({
      ...prevState,
      ["others"]: organizedData?.others_PossibleFields || [],
    }));
  }, []);


  useEffect(() => {
    // Set default values dynamically based on some logic
    if(companyHierarchySettingArray?.length>0){
    const dynamicDefaults = {};
    companyHierarchySettingArray?.forEach((labelName) => {
      dynamicDefaults[labelName] = "default";
    });
    setSelectedCompanyHierarchy(dynamicDefaults);
  }
  }, [companyHierarchySettingArray]);

  useEffect(() => {
    if (params.id) getClientsList();
    initiateChData();
    if (!params.id) getBranches(() => {});
  }, []);

  const AllClientSettings = () => {
    const handleChange = (event, label) => {
      setSelectedRolesValue((prevState) => ({
        ...prevState,
        ["others"]: prevState["others"].map((item) => {
          if (item.keyName === label) {
            return {
              ...item,
              value: item.value === "true" ? "false" : "true", // Toggling the value
            };
          }
          return item;
        }),
      }));
    };
    const handleChangeForRoles = (event, item) => {
      const { keyName } = item;
      const newValue = event.target.checked ? item.label : "false";

      setSelectedRolesValue((prevState) => ({
        ...prevState,
        [keyName]: { ...prevState[keyName], value: newValue },
      }));
    };

    const findCurrentClientID = () => {
      if (!params.id) {
        axiosWithToken
          .get(BASE_URL + "clients?_fields=id&_sort=-id&_size=1")
          .then((res) => {
            let idIs = res?.data[0]?.id + 1;
            setCurrentClientID(idIs);
          });
      } else {
        setCurrentClientID(parseInt(params.id));
      }
    };

    useEffect(() => {
      const allObjects = Object.values(selectedRolesValue).map((obj) => {
        if (Array.isArray(obj)) {
          return Object.values(obj);
        }
        return obj;
      });

      const flattenedArray = allObjects.flat().map((obj) => {
        if (obj.label) {
          const { label, ...rest } = obj;
          return { ...rest, clientId: currentClientID };
        }
        return { ...obj, clientId: currentClientID };
      });
      setAllSettingsArray(flattenedArray);
    }, [selectedRolesValue, currentClientID]);

    const fetchClientSettingsData=async()=>{
      try{
        const sqlQuery = {
          query: `SELECT keyName, value,companyHierarchy  FROM clientSettings WHERE clientId = ${params.id} `,
        };
  
        const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
        setClientSettingsData(res?.data)

        const response = res.data;

        // Use map to extract companyHierarchy and filter out empty strings and null values
        const HierarchyArray = response
          .map(ele => ele.companyHierarchy)
          .filter(hierarchy => hierarchy !== null && hierarchy !== "");
        
        // Use Set to ensure uniqueness and then convert back to array
        const uniqueArray = [...new Set(HierarchyArray)];
        
        // Split each element by "," and create an array of arrays
        const resultArray = uniqueArray.reduce((acc, element) => {
          const splitValues = element.split(',');
          splitValues.forEach((value, index) => {
            if (!acc[index]) {
              acc[index] = [];
            }
            acc[index].push(value);
          });
          return acc;
        }, []);

        setHierarchyResultArray(resultArray)
      // Remove duplicates within each inner array
const uniqueData = resultArray.map(innerArray => [...new Set(innerArray)]);
        setCompanyHierarchyOptions(uniqueData)

      }catch (error) {
        console.error(error);
      }
   
    }

    const updateCompanyHierarchyOptions = () => {
      const selectedDivision = selectedCompanyHierarchy?.Division || "default";
    
      const allIndices = hierarchyResultArray[0]?.map((item, index) => 
        item === selectedDivision ? index : null
      ).filter(index => index !== null);
    
      const result = hierarchyResultArray.map((el, i) => 
        i === 0 ? el : el.filter((item, ind) => allIndices.includes(ind))
      );
    
      const uniqueData = result.map(innerArray => [...new Set(innerArray)]);
      setCompanyHierarchyOptions(uniqueData);
    };
    
    useEffect(() => {
      updateCompanyHierarchyOptions();
    }, [selectedCompanyHierarchy?.Division]);
    
 

useEffect(()=>{
  
  const filterClientSettingsData=async()=>{
    try{
     let filterData=ClientSettingsData?.filter((ele)=>ele.companyHierarchy==newCompanyHierarchy)
     const newFilterArray = filterData?.map(({ companyHierarchy, ...rest }) => rest);

     setFilterClientSettingsArray(newFilterArray)
    }catch (error) {
      console.error(error);
    }
 
  }
  filterClientSettingsData()
},[newCompanyHierarchy,ClientSettingsData])

    useEffect(() => {
      const fetchData = async () => {
        try {
          // const sqlQuery = {
          //   query: `SELECT keyName, value FROM clientSettings WHERE clientId = ${params.id} and companyHierarchy='${newCompanyHierarchy}'`,
          // };

          // const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
          // const fetchedData = res.data;

          setSelectedRolesValue((prevState) => {
            const updatedRoles = { ...prevState };

            filterClientSettingsArray.forEach((item) => {
              const { keyName, value } = item;

              if (keyName.includes("role") && updatedRoles[keyName]) {
                updatedRoles[keyName].value = value;
              } else {
                const otherIndex = updatedRoles.others.findIndex(
                  (obj) => obj.keyName === keyName
                );

                if (otherIndex !== -1) {
                  updatedRoles.others[otherIndex].value = value;
                }
              }
            });

            return updatedRoles;
          });
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [filterClientSettingsArray]);

    useEffect(()=>{
      if(params.id) fetchClientSettingsData()
     },[params.id])

    useEffect(() => {
      findCurrentClientID();
    }, []);

    return (
      <>
        {isSettingSection && (
          <Box id="client_edit_extrasetting">
            <Typography
              variant="h6"
              gutterBottom
              align="left"
              sx={{ margin: "1.5rem 0px" }}
            >
              Settings
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} style={TableCellCss}>
                      <Typography variant="paragraph">
                        <b>Organization (For Signature) </b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* Role 1 */}
                  <TableRow>
                    <TableCell style={TableCellCss}>
                      <Typography variant="paragraph">Role1:</Typography>
                    </TableCell>
                    <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role1_PossibleFields?.map(
                        (item, index) => (
                          <FormControlLabel
                            style={{ height: "20px", margin: "3px" }}
                            key={index}
                            control={
                              <Checkbox
                                checked={
                                  selectedRolesValue?.role1 &&
                                  selectedRolesValue?.role1?.value ===
                                    item?.label
                                }
                                onChange={(event) =>
                                  handleChangeForRoles(event, item)
                                }
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {item.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>

                  {/* Role 2 */}
                  <TableRow>
                    <TableCell style={TableCellCss}>
                      <Typography variant="paragraph">Role2:</Typography>
                    </TableCell>
                    <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role2_PossibleFields?.map(
                        (item, index) => (
                          <FormControlLabel
                            style={{ height: "20px", margin: "3px" }}
                            key={index}
                            control={
                              <Checkbox
                                checked={
                                  selectedRolesValue?.role2 &&
                                  selectedRolesValue?.role2?.value ===
                                    item.label
                                }
                                onChange={(event) =>
                                  handleChangeForRoles(event, item)
                                }
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {item.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>

                  {/* Role1Label */}
                  <TableRow>
                    <TableCell style={TableCellCss}>
                      <Typography variant="paragraph">Role1Label:</Typography>
                    </TableCell>
                    <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role1Label_PossibleFields?.map(
                        (item, index) => (
                          <FormControlLabel
                            style={{ height: "20px", margin: "3px" }}
                            key={index}
                            control={
                              <Checkbox
                                checked={
                                  selectedRolesValue?.role1Label &&
                                  selectedRolesValue?.role1Label?.value ===
                                    item.label
                                }
                                onChange={(event) =>
                                  handleChangeForRoles(event, item)
                                }
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {item.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>


                  {/* Role2Label */}
                  <TableRow>
                    <TableCell style={TableCellCss}>
                      <Typography variant="paragraph">Role2Label:</Typography>
                    </TableCell>
                    <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role2Label_PossibleFields?.map(
                        (item, index) => (
                          <FormControlLabel
                            style={{ height: "20px", margin: "3px" }}
                            key={index}
                            control={
                              <Checkbox
                                checked={
                                  selectedRolesValue?.role2Label &&
                                  selectedRolesValue?.role2Label?.value ===
                                    item.label
                                }
                                onChange={(event) =>
                                  handleChangeForRoles(event, item)
                                }
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {item.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>

                  {allRole_PossibleFields?.role3_PossibleFields ||
                  allRole_PossibleFields?.role4_PossibleFields ||
                  allRole_PossibleFields?.role5_PossibleFields ? (
                    <TableRow>
                      <TableCell colSpan={2} style={TableCellCss}>
                        <Typography variant="paragraph">
                          <b>Clients (For Signature)</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {/* Role 3 */}
                  {allRole_PossibleFields?.role3_PossibleFields && (
                    <TableRow>
                      <TableCell style={TableCellCss}>
                        <Typography variant="paragraph">Role3:</Typography>
                      </TableCell>
                      <TableCell style={TableCellCss}>
                        {allRole_PossibleFields?.role3_PossibleFields?.map(
                          (item, index) => (
                            <FormControlLabel
                              style={{ height: "20px", margin: "3px" }}
                              key={index}
                              control={
                                <Checkbox
                                  checked={
                                    selectedRolesValue?.role3 &&
                                    selectedRolesValue?.role3?.value ===
                                      item?.label
                                  }
                                  onChange={(event) =>
                                    handleChangeForRoles(event, item)
                                  }
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {item.label}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Role 4 */}
                  {allRole_PossibleFields?.role4_PossibleFields && (
                    <TableRow>
                      <TableCell style={TableCellCss}>
                        <Typography variant="paragraph">Role4:</Typography>
                      </TableCell>
                      <TableCell style={TableCellCss}>
                        {allRole_PossibleFields?.role4_PossibleFields?.map(
                          (item, index) => (
                            <FormControlLabel
                              style={{ height: "20px", margin: "3px" }}
                              key={index}
                              control={
                                <Checkbox
                                  checked={
                                    selectedRolesValue?.role4 &&
                                    selectedRolesValue?.role4?.value ===
                                      item.label
                                  }
                                  onChange={(event) =>
                                    handleChangeForRoles(event, item)
                                  }
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {item.label}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Role 5 */}
                  {allRole_PossibleFields?.role5_PossibleFields && (
                    <TableRow>
                      <TableCell style={TableCellCss}>
                        <Typography variant="paragraph">Role5:</Typography>
                      </TableCell>
                      <TableCell style={TableCellCss}>
                        {allRole_PossibleFields?.role5_PossibleFields?.map(
                          (item, index) => (
                            <FormControlLabel
                              style={{ height: "20px", margin: "3px" }}
                              key={index}
                              control={
                                <Checkbox
                                  checked={
                                    selectedRolesValue?.role5 &&
                                    selectedRolesValue?.role5?.value ===
                                      item.label
                                  }
                                  onChange={(event) =>
                                    handleChangeForRoles(event, item)
                                  }
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {item.label}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Miscellaneous */}
                  {allRole_PossibleFields?.others_PossibleFields &&
                    allRole_PossibleFields?.others_PossibleFields?.length >
                      0 && (
                      <>
                        <TableRow>
                          <TableCell colSpan={2} style={TableCellCss}>
                            <Typography variant="paragraph">
                              <b>Miscellaneous</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={TableCellCss}>
                            <Typography variant="paragraph">Other:</Typography>
                          </TableCell>
                          <TableCell style={TableCellCss}>
                            {allRole_PossibleFields?.others_PossibleFields?.map(
                              (item, index) => (
                                <FormControlLabel
                                  style={{ height: "20px", margin: "3px" }}
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedRolesValue?.others &&
                                        selectedRolesValue?.others?.find(
                                          (ele) => ele.label === item.label
                                        )?.value === "true"
                                      }
                                      onChange={(event) =>
                                        handleChange(event, item.label)
                                      }
                                      name={item.label}
                                    />
                                  }
                                  label={
                                    <Typography variant="caption">
                                      {item.label}
                                    </Typography>
                                  }
                                />
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </>
    );
  };


  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {/* Edit Client */}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editClientGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="client_edit_compname"
            label="Client *"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyNanme(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="outlined-basic"
            label="Billing Address"
            size="small"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>{" "} */}
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Autocomplete
            fullWidth
            id="client_edit_branch"
            size="small"
            options={branches || []}
            defaultValue={undefined}
            value={selectedBranch || null}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.branchName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Branch"
                placeholder="Select Branch"
              />
            )}
            onChange={(event, value) => setSelectedBranch(value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="client_edit_GST"
            label="GST Number"
            size="small"
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: 'off',
            }}
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={6} sm={6} md={4} lg={3}>
        <Autocomplete
          options={indianStates}
          value={state}
          onChange={(event, newValue) => setState(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
            label="State"
            size="small"
            fullWidth
            variant="outlined"
            inputProps={{
              autoComplete: 'off',
                ...params.inputProps,
            }}
            />
          )}
        />
        </Grid>
        {/* <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="outlined-basic"
            label="Email *"
            size="small"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <MultiAddressInput
            addressIds={addressIds}
            shippingAddressIds={shippingAddressIds}
            setAddressIds={setAddressIds}
            setShippingAddressIds={setShippingAddressIds}
            allAddresses={allAddresses}
            setAllAddresses={setAllAddresses}
            params={params}
          />
        </Grid>
      </Grid>
      <br />
      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
        }}
      />
      {companyHierarchySettingArray && (
    <Grid container spacing={2} sx={{margin:"15px 0px"}}>
    {companyHierarchySettingArray?.map((labelName,index)=>{

    return(
    <Grid item xs={6} sm={6} md={4} lg={3}>
    <Autocomplete
    key={selectedCompanyHierarchy[labelName]}
          value={selectedCompanyHierarchy[labelName]}
          onChange={(event, newValue) => {
            handleCompanyHierarchyChange(labelName, newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push(inputValue);
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={companyHierarchyOptions?.[index]?.includes('default') ? companyHierarchyOptions?.[index] : companyHierarchyOptions?.[index]?.concat('default') || ['default']}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // new  option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
          }}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label={labelName} />
          )}
          size="small"
        />

      </Grid>
    )})}
    </Grid>
      )}
   
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ float: "left", margin: "20px 0px" }}
        >
          Contact Details
        </Typography>
        <Button
          id="client_edit_addcontact"
          variant="contained"
          size="small"
          onClick={(e) => {
            addRangeRow(0);
          }}
        >
          Add Contact
        </Button>
      </Box>

      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns?.map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactDetailsRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>
                      {cellIndex === 0 ? (
                        <Autocomplete
                          id="client_edit_contvalue"
                          options={allAddresses}
                          size="small"
                          getOptionLabel={(option) => option?.address}
                          value={cell !== null ? { address: cell } : null}
                          onChange={(event, value) => {
                            let optionId = value?.address;
                            updateCellValue(index, cellIndex, optionId);
                          }}
                          sx={{ width: "200px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Address *"
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          id="client_edit_contvalue"
                          size="small"
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                          type={cellIndex == 4 ? 'email' : 'text'}
                          inputMode={cellIndex == 3 ? 'numeric' : undefined}
                          maxLength={cellIndex == 3 ? 10 : undefined}
                          error={
                            readingColumns[cellIndex+1].validate &&
                            !readingColumns[cellIndex+1].validate(cell)
                          }
                          helperText={
                            readingColumns[cellIndex+1].errorText &&
                            !readingColumns[cellIndex+1].validate(cell)
                              ? readingColumns[cellIndex+1].errorText
                              : ''
                          }
                        />
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <DeleteIcon
                      id="client_edit_contdelte"
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />
      </div>

      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
          margin: "5px 0px",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>{AllClientSettings()}</Box>
        </Grid>
      </Grid>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
        <Button
          id="client_edit_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          onClick={() => {
            if (params.id == undefined) {
              if (!companyName) {
                toast.error('Client Name is required');
                return;
              }
              if (!selectedBranch) {
                toast.error('Please select a branch');
                return;
              }
              if (!allAddresses[0]?.address) {
                toast.error('At least one address is required');
                return;
              }
              if (contactDetailsRows.length == 0) {
                toast.error('At least one contact is required');
                return;
              }
            }
            if (contactDetailsRows.length > 0) {
              let isValid = true;
        
              contactDetailsRows.forEach((row) => {
                if (
                 !row[0] || // Address
                 !row[1] || // dept
                 !row[2] || // Contact Person Name
                 !row[3] || // Contact No
                 !row[4] // Email Id
                ) {
                  isValid = false;
                }
              });
        
              if (!isValid) {
                toast.error("All fields are required for each contact detail");
                return;
              }
            }
            props.setLoader(true);
            onSubmit();
          }}
        >
          {params.id ? "Update" : "Save"}
        </Button>
        </Tooltip>
      </Toolbar>
      <ToastContainer />
    </EditWrap>
  );
}