import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import {
  Grid,
  TextField,
  Autocomplete,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from "./../../global";
import ExcelDownload from "../../utils/components/excelDownload";
import html2pdf from "html2pdf.js";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { ToastContainer, toast } from "react-toastify";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import ListWrap from "../commonComponent/listWrap";
import { accuracyFormat, parseRange } from "../../utils/components/accuracyandlcandrangesshow";
import { generatePDF1 } from "./summaryPrint";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const printColumns = [
  // { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate No.", minWidth: 40 },
  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  {
    id: "ranges",
    label: "Range",
    minWidth: 20,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 20,
    align: "left",
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 20,
    align: "left",
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    minWidth: 20,
    align: "left",
  },
  {
    id: "ducId",
    label: "Duc Id",
    minWidth: 20,
    align: "left",
  },
  {
    id: "calibratedBy",
    label: "Calibrated By",
    minWidth: 40,
    align: "left",
  },

  {
    id: "calibrationDate",
    label: "Calibration Date",
    minWidth: 20,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due Date",
    minWidth: 40,
    align: "left",
  },
];

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "ulrNo", label: "ULR No.", minWidth: 40 },
  // { id: "companyName", label: "Client", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate No.", minWidth: 40 },

  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  // {
  //   id: "companyHierarchy",
  //   label: "Division,Department",
  //   align: "left",
  //   minWidth: 40,
  // },
  {
    id: "companyHierarchy",
    label: "Division, Department",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ranges",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 40,
    align: "left",
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    minWidth: 40,
    align: "left",
  },
  {
    id: "ducId",
    label: "Duc Id",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    minWidth: 40,
    align: "left",
  },
  {
    id: "model",
    label: "Model",
    minWidth: 40,
    align: "left",
  },
  {
    id: "locationOfInstrument",
    label: "Duc Location",
    minWidth: 40,
    align: "left",
  },
  {
    id: "calibratedBy",
    label: "Calibrated By",
    minWidth: 40,
    align: "left",
  },

  {
    id: "calibrationDate",
    label: "Calibration date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due date",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  ulrNo,
  companyName,
  companyHierarchy,
  certificateNumber,
  InstrumentId,
  ranges,
  lc,
  accuracy,
  serialNumber,
  ducId,
  make,
  model,
  locationOfInstrument,
  calibratedBy,
  calibrationDate,
  dueDate,
  

) {
  return {
    id,
    ulrNo,
    companyName,
    companyHierarchy,
    certificateNumber,
    InstrumentId,
    ranges,
    lc,
    accuracy,
    serialNumber,
    ducId,
    make,
    model,
    locationOfInstrument,
    calibratedBy,
    calibrationDate,
    dueDate,
  
 
  };
}
let notNeededColumn = ["id"];

const filter = createFilterOptions();
export default function SummaryReport() {
  const [page, setPage] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [fromDate, setFromDate] = React.useState("");
  const [data, setData] = React.useState("");
  const [company, setCompany] = React.useState([]);
  const [toDate, setToDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [dateData, setDateData] = React.useState([]);
  const [calData, setCalData] = React.useState([]);
  const [instrumentCount, setInstrumentCount] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [settingList, setSettingList] = React.useState({});
  const _companyHierarchy = localStorage.getItem("companyHierarchy") || null;
  const printComponentRef = React.useRef();
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [isPrint, setIsPrint] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  const [selectedCompanyHierarchy, setSelectedCompanyHierarchy] =
    React.useState({});
  const [companyHierarchyOptions, setCompanyHierarchyOptions] = React.useState([
    [],
    [],
  ]);
  const [hierarchyResultArray, setHierarchyResultArray] = React.useState([
    [],
    [],
  ]);
  const [companyHierarchy, setCompanyHierarchy] = React.useState(null);

console.log("data",data)

  const FilterData = () => {
    if (!data) {
      setDateData([]);
      return;
    }

    // Precompute date boundaries
    const startDate = new Date(fromDate).setHours(0, 0, 0, 0);
    const endDate = new Date(toDate).setHours(23, 59, 59, 999);

    const resultProductData = data.filter((datas) => {
      const isDateInRange =
        new Date(datas.ds_calibrationDate) >= startDate &&
        new Date(datas.ds_calibrationDate) <= endDate;

      const isCompanyIncluded = !company || company.includes(datas.client_id);
      const isHierarchyMatched =
        (!companyHierarchy ||
          companyHierarchy === datas.srf_companyHierarchy) &&
        (_userType !== "3" ||
          _companyHierarchy === datas.srf_companyHierarchy);

      return isDateInRange && isCompanyIncluded && isHierarchyMatched;
    });

    setDateData(resultProductData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    let endPoint = "";
    endPoint =
      _userType == "3"
        ? `clients/${_clientId}?_where=(status,eq,1)`
        : `clients?_where=(status,eq,1)`;
    axiosWithToken
      .get(url + endPoint)
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      id: customer[i].id,
      label: customer[i].companyName,
    });
  }

  const getData = (event) => {
    let url = BASE_URL;
    axiosWithToken
    .get(url + `xjoin?_join=ds.datasheets,_j,client.clients,_j,inst.instruments,_j,srf.srfs,_j,cert.certificates,_j,srfInstrument.srfInstruments,_j,user.users&_on1=(ds.clientId,eq,client.id)&_on2=(ds.instrumentId,eq,inst.id)&_on3=(ds.serviceReqNumber,eq,srf.serviceReqNumber)&_on4=(cert.id,eq,ds.id)&_on5=(srfInstrument.id,eq,ds.id)&_on6=(ds.calibratedBy,eq,user.id)&_where=(client.id,in,${company?.join(",")})~and(srfInstrument.status,eq,1)&_fields=client.companyName,client.contact,client.address,inst.instrumentName,cert.ULRNo,cert.certificateNumber,ds.calibrationDate,ds.nextDueDate,client.id,ds.id,ds.lc,ds.DUCID,ds.make,ds.model,ds.ranges,srf.companyHierarchy,ds.accuracy,ds.serialNumber,cert.locationOfInstrument,srfInstrument.requestedDucName,user.userName`)

      .then((res) => {
        setData(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    for (let i = 0; i < dateData.length; i++) {
      idAr.push(dateData[i].ds_id);
      rowData.push(
        createData(
          dateData[i].ds_id,
          dateData[i].cert_ULRNo,
          dateData[i].client_companyName,
          dateData[i].srf_companyHierarchy,
          dateData[i].cert_certificateNumber,
          dateData[i].srfInstruments_requestedDucName
            ? dateData[i].srfInstruments_requestedDucName
            : dateData[i].inst_instrumentName,
            parseRange(dateData[i].ds_ranges),
          dateData[i].ds_lc?.replaceAll("|", ", ")?.replaceAll("#", " "),
          accuracyFormat( dateData[i].ds_accuracy),
          dateData[i].ds_serialNumber,
          dateData[i].ds_DUCID,
          dateData[i].ds_make,
          dateData[i].ds_model,
          dateData[i].cert_locationOfInstrument,
          dateData[i].user_userName,
          dateData[i].ds_calibrationDate
            ? moment(dateData[i].ds_calibrationDate).format("DD-MM-YYYY")
            : "",
          dateData[i].ds_nextDueDate
            ? moment(dateData[i].ds_nextDueDate).format("DD-MM-YYYY")
            : "",        
       
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
        let settings = res.data;
        let _settingList = {};
        settings.forEach((setting) => {
          _settingList[setting.keyName] = setting.value;
        });
        setSettingList(_settingList);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(err.message);
        }
      });
  }

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [10, 1, 20, 1],
          filename: `masterInOut.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 2.5,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: "png", quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 10;
        const borderX = leftMargin;
        const borderY = 5;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 830);

        // const text = "SUMMARY REPORT";
        // const pageWidth = pdf.internal.pageSize.getWidth();
        // const textWidth =
        //   (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor;
        // const x = (pageWidth - textWidth) / 2;
        // pdf.setFontSize(12);
        // pdf.text(text, x, 20);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `summaryReport.pdf`;
      a.click();

      setDownlaodPdfLoading(false);

      refresh();
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  function processCalData(calData) {
    const uniqueCompanyNames = new Set();
    const instrumentCountMap = new Map();

    calData.forEach((item) => {
      // Add the company name to the Set (ensures uniqueness)
      if (item.companyName) {
        uniqueCompanyNames.add(item.companyName);
      }

      // Count the occurrence of each InstrumentId
      if (item.InstrumentId) {
        if (instrumentCountMap.has(item.InstrumentId)) {
          instrumentCountMap.set(
            item.InstrumentId,
            instrumentCountMap.get(item.InstrumentId) + 1
          );
        } else {
          instrumentCountMap.set(item.InstrumentId, 1);
        }
      }
    });

    // Convert the Set to an array for unique company names
    const companyNamesArray = Array.from(uniqueCompanyNames);

    // Convert the Map to an array of objects for instrument counts
    const instrumentCountsArray = Array.from(
      instrumentCountMap,
      ([InstrumentId, count]) => ({
        InstrumentId,
        count,
      })
    );

    return {
      companyNamesArray,
      instrumentCountsArray,
    };
  }

  const fetchClientSettingsData = async () => {
    try {
      const sqlQuery = {
        query: `SELECT keyName, value,companyHierarchy  FROM clientSettings WHERE clientId IN (${company?.join(
          ","
        )})`,
      };

      const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery, {
        headers: { requestType: "fetchData" },
      });

      const response = res.data;

      // Use map to extract companyHierarchy and filter out empty strings and null values
      const HierarchyArray = response
        .map((ele) => ele.companyHierarchy)
        .filter((hierarchy) => hierarchy !== null && hierarchy !== "");

      // Use Set to ensure uniqueness and then convert back to array
      const uniqueArray = [...new Set(HierarchyArray)];

      // Split each element by "," and create an array of arrays
      const resultArray = uniqueArray.reduce((acc, element) => {
        const splitValues = element.split(",");
        splitValues.forEach((value, index) => {
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(value);
        });
        return acc;
      }, []);

      setHierarchyResultArray(resultArray);
      // Remove duplicates within each inner array
      const uniqueData = resultArray.map((innerArray) => [
        ...new Set(innerArray),
      ]);
      setCompanyHierarchyOptions(uniqueData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanyHierarchyChange = (label, value) => {
    setSelectedCompanyHierarchy((prev) => {
      const updatedHierarchy = {
        ...prev,
        [label]: value,
      };

      const newCompanyHierarchy = Object.values(updatedHierarchy).join(",");
      setCompanyHierarchy(newCompanyHierarchy);

      return updatedHierarchy;
    });
  };

  const updateCompanyHierarchyOptions = () => {
    const selectedDivision = selectedCompanyHierarchy?.Division || "default";

    const allIndices = hierarchyResultArray[0]
      ?.map((item, index) => (item === selectedDivision ? index : null))
      .filter((index) => index !== null);

    const result = hierarchyResultArray.map((el, i) =>
      i === 0 ? el : el.filter((item, ind) => allIndices.includes(ind))
    );

    const uniqueData = result.map((innerArray) => [...new Set(innerArray)]);
    setCompanyHierarchyOptions(uniqueData);
  };

  useEffect(() => {
    updateCompanyHierarchyOptions();
  }, [selectedCompanyHierarchy?.Division]);

  useEffect(() => {
    if (company) fetchClientSettingsData();
  }, [company]);

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };
  const search = () => {
    setSearched(true);
    FilterData();
  };
  const clearSearch = () => {
    setSearched(false);
    setCompany([]);
    setFromDate("");
    setToDate("");
    setDateData([]);
  };

  useEffect(() => {
    getCustomer();
    // getData();
    fetchSettings();
  }, []);
  useEffect(() => {
    initializeRowTable();
  }, [dateData]);

  useEffect(() => {
   if(company?.length > 0) getData();
  }, [company]);

  useEffect(() => {
    let result = processCalData(calData);
    setInstrumentCount(result);
  }, [calData]);


  return (
    <ListWrap>
      <Grid
        container
        spacing={1}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={12} md={3} lg={3} textAlign={"left"}>
          <Autocomplete
            size="small"
            className="Listinputcss"
            id="combo-box-demo"
            options={pushArray}
            disableCloseOnSelect
            multiple
            value={pushArray.filter((option) => company.includes(option.id))}
            onChange={(event, value) => {
              const selectedIds = value.map((e) => e.id);

              setCompany(selectedIds);
              setSearched(false);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={company.includes(option.id)}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Client" />}
          />
        </Grid>
        {settingList["companyHierarchy"] &&
          _userType !== "3" &&
          company?.length < 2 && (
            <>
              {/* <Grid container spacing={2}> */}
              {settingList["companyHierarchy"]
                ?.split(",")
                ?.map((labelName, index) => (
                  <Grid item xs={6} sm={3} md={2} lg={2} key={index}>
                    <Autocomplete
                      className="Listinputcss"
                      onChange={(event, newValue) => {
                        handleCompanyHierarchyChange(labelName, newValue);
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                      }}
                      // disabled={params.id != 0}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="uncertinity_edit_condcoeff"
                      options={
                        companyHierarchyOptions?.[index]?.includes("default")
                          ? companyHierarchyOptions?.[index]
                          : companyHierarchyOptions?.[index]?.concat("default")
                      }
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option}</li>
                      )}
                      freeSolo={false}
                      renderInput={(params) => (
                        <TextField {...params} label={labelName} />
                      )}
                      size="small"
                    />
                  </Grid>
                ))}
              {/* </Grid> */}
              {/* <br /> */}
            </>
          )}
        <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
          <TextField
            id="Start Date"
            label="Date From"
            variant="outlined"
            type="date"
            className="Listinputcss"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
              setSearched(false);
            }}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
          <TextField
            id="Start Date"
            label="Date To"
            variant="outlined"
            type="date"
            className="Listinputcss"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
              setSearched(false);
            }}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            className="Listinputcss"
            color={searched ? "error" : "primary"}
            sx={{ m: 0 }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>
        {ids.length > 0 && (
          <>
            <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
              <ExcelDownload
                finalData={calData}
                notNeededColumn={notNeededColumn}
              />
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
              <Button
                variant="contained"
                size="small"
                sx={{ height: "40px" }}
                // onClick={() => {
                //   setIsPrint(true);
                //   generatePDF();
                // }}
                onClick={() =>
                  generatePDF1({
                    calData,
                    printColumns,
                    instrumentCount,
                    fromDate,
                    toDate,
                  })
                }
              >
                Download PDF
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <TableContainer>
        <div
          style={{ width: isPrint ? "95%" : "100%", margin: "auto" }}
          ref={printComponentRef}
        >
          {isPrint ? (
            <>
              <div style={{ padding: "5px 10px" }}>
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    INDEX
                  </h4>
                  <br />
                  {/* <h6>
              INSPECTION DETAILS OF TESTING OF HOIST, LIFT & LIFTING MACHINERY
            </h6> */}
                </div>
                <div
                  id="summaryBorder"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  <table style={{ borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontSize: "15px",
                            border: "none",
                            width: "40%",
                          }}
                        >
                          Clients Name{" "}
                        </td>
                        <td
                          style={{
                            fontSize: "15px",
                            border: "none",
                            width: "60%",
                            fontWeight: 100,
                          }}
                        >
                          : &nbsp;{" "}
                          {instrumentCount?.companyNamesArray?.join(", ")}
                        </td>
                      </tr>
                      {companyHierarchy && (
                        <>
                          <tr>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "40%",
                              }}
                            >
                              Division
                            </td>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "60%",
                                fontWeight: 100,
                              }}
                            >
                              : &nbsp;{companyHierarchy.split(",")[0]}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "40%",
                              }}
                            >
                              Department
                            </td>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "60%",
                                fontWeight: 100,
                              }}
                            >
                              : &nbsp;{companyHierarchy.split(",")[1]}
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <td
                          style={{
                            fontSize: "15px",
                            border: "none",
                            width: "40%",
                          }}
                        >
                          Date Of calibration{" "}
                        </td>
                        <td
                          style={{
                            fontSize: "15px",
                            border: "none",
                            width: "60%",
                            fontWeight: 100,
                          }}
                        >
                          : &nbsp;{" "}
                          {` From ${
                            fromDate
                              ? moment(fromDate).format("DD-MM-YYYY")
                              : null
                          } To ${
                            toDate ? moment(toDate).format("DD-MM-YYYY") : null
                          }`}{" "}
                        </td>
                      </tr>
                      {instrumentCount?.instrumentCountsArray?.map(
                        (instrument, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "40%",
                              }}
                            >
                              {instrument.InstrumentId}{" "}
                            </td>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "60%",
                                fontWeight: 100,
                              }}
                            >
                              :&nbsp; &nbsp; {instrument.count}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : null}

          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            style={{ borderCollapse: "collapse", marginTop: "5px" }}
          >
            <TableBody className="single-border-table">
              <TableRow>
                {(isPrint ? printColumns : columns)?.map((column) =>
                  !settingList["companyHierarchy"] &&
                  column.id === "companyHierarchy" ? null : column.id ===
                    "companyHierarchy" ? (
                    // Handle the case for "companyHierarchy" to create two TableCell elements
                    column.label.split(",").map((labelPart, index) => (
                      <TableCell
                        key={`${column.id}-${index}`}
                        align={column.align}
                        style={{
                          minWidth: `${column.minWidth}%`,
                          fontSize: isPrint ? "11px" : "14px",
                        }}
                      >
                        <b>{labelPart.trim()}</b>
                      </TableCell>
                    ))
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: `${column.minWidth}%`,
                        fontSize: isPrint ? "11px" : "14px",
                      }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  )
                )}
              </TableRow>

              {calData?.length > 0 ? (
                calData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        id="pagbreaksborder"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{ pageBreakInside: "avoid" }}
                      >
                        {(isPrint ? printColumns : columns)?.map((column) => {
                          let value = row[column.id];

                          if (column.id === "id") {
                            value = page * rowsPerPage + index + 1;
                          }

                          if (column.id === "companyHierarchy") {
                            // Handle the case where "companyHierarchy" value needs to be split
                            const values = value ? value.split(",") : [];
                            return values.map((val, i) => (
                              <TableCell
                                key={`${column.id}-${i}`}
                                align={column.align}
                                style={{ fontSize: "11px" }}
                              >
                                {val.trim()}
                              </TableCell>
                            ));
                          }

                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ fontSize: "11px" }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    <h6>
                      <b>Data Not Found</b>
                    </h6>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 30, 100, { value: calData.length, label: "All" }]}
          component="div"
          count={calData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {downloadPdfloading && (
        <DownloadPdfLoaderModal
          loading={downloadPdfloading}
          name={"Summary Report"}
        />
      )}
    </ListWrap>
  );
}
